import api from '../utils/configAxios';

const sessionService = {
  createSession: async (sessionId, userId) => {
    try {
      const response = await api.post('/sessions', { sessionId, userId });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Error al crear la sesión');
    }
  },

  getAllSessions: async () => {
    try {
      const response = await api.get('/sessions');
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Error al obtener las sesiones');
    }
  },

  getSessionById: async (stripeSessionid) => {
    try {
      const response = await api.get(`/sessions/${stripeSessionid}`);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Error al obtener la sesión');
    }
  },

  markSessionAsUsed: async (id) => {
    try {
      const response = await api.patch(`/sessions/${id}/use`);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Error al marcar la sesión como usada');
    }
  },

  deleteSession: async (id) => {
    try {
      const response = await api.delete(`/sessions/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Error al eliminar la sesión');
    }
  },

  markSessionAsUsedById: async (id) => {
    try {
      const response = await api.patch(`/sessions/${id}/markAsUsed`);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Error al marcar la sesión como utilizada');
    }
  },
};

export default sessionService;