import React, { useState, useEffect } from 'react';
import { useGlobal } from '../context/GlobalContext';
import { useLocation, useNavigate } from 'react-router-dom';
import CheckoutService from '../services/checkout';
import { FiCheckCircle, FiAlertCircle, FiLoader, FiArrowLeft } from 'react-icons/fi';
import ConsultaService from '../services/Consulta';

const OrderConfirmationPage = () => {
 const location = useLocation();
 const navigate = useNavigate();
 const [verificationStatus, setVerificationStatus] = useState({
   state: 'loading', 
   message: 'Verificando tu pago...'
 });
 const { state, dispatch } = useGlobal();
 const { user } = state;
 console.log("user.email", user.email)

 const handleSessionVerification = async (sessionId) => {
  try {
    console.log('Starting session verification for:', sessionId);
    const response = await CheckoutService.verifyCheckoutSession(sessionId);
    // await ConsultaService.updateConsultaByUserId(user.id);

    
    if (response.success && user) {
      // Tracking code for marketing
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'purchase',
        email: user.email,
        ecommerce: {
          currency: 'EUR',
          value: response.amount,
          transaction_id: sessionId,
          items: [{
            item_name: response.subscriptionCategory,
            item_id: response.subscriptionId,
            price: response.amount,
          }]
        }
      });
 
      setVerificationStatus({
        state: 'success',
        message: response.message || 'Pago completado exitosamente'
      });
    } else {
      throw new Error('Falló la verificación del pago');
    }
  } catch (error) {
    console.error('Error en verificación:', error);
    setVerificationStatus({
      state: 'error',
      message: error.message || 'Error al procesar el pago'
    });
  }
 };

useEffect(() => {
  const verifyPayment = async () => {
    console.log('Starting payment verification');
    const sessionId = new URLSearchParams(location.search).get('session_id');
    console.log('Session ID from URL:', sessionId);
    
    if (!sessionId) {
      console.log('No session ID found');
      setVerificationStatus({
        state: 'error',
        message: 'Sesión de pago no encontrada',
        errorDetails: { code: 'MISSING_SESSION_ID' }
      });
      return;
    }

    try {
      await handleSessionVerification(sessionId);
    } catch (error) {
      console.error('Critical error in payment verification:', error);
      setVerificationStatus({
        state: 'error',
        message: 'Error crítico en el proceso de pago',
        errorDetails: error.details
      });
    }
  };

  verifyPayment();
}, [location.search]);
 const renderStatusContent = () => {
   const statusConfig = {
     loading: {
       icon: <FiLoader className="w-16 h-16 animate-spin text-blue-600" />,
       title: 'Procesando pago',
       colorClass: 'bg-blue-50 border-blue-200'
     },
     success: {
       icon: <FiCheckCircle className="w-16 h-16 text-green-500" />,
       title: '¡Pago completado!',
       colorClass: 'bg-green-50 border-green-200'
     },
     error: {
       icon: <FiAlertCircle className="w-16 h-16 text-red-500" />,
       title: 'Error en el pago',
       colorClass: 'bg-red-50 border-red-200'
     }
   };

   const { icon, title, colorClass } = statusConfig[verificationStatus.state];

   return (
     <div className={`flex flex-col items-center justify-center p-6 rounded-xl border ${colorClass}`}>
       {icon}
       <h2 className="mt-4 text-xl font-semibold text-gray-800">{title}</h2>
       <p className="mt-2 text-center text-gray-600">{verificationStatus.message}</p>
       
       {verificationStatus.details?.subscriptionId && (
         <div className="mt-4 text-sm text-gray-500">
           <p>ID de suscripción: {verificationStatus.details.subscriptionId}</p>
         </div>
       )}
     </div>
   );
 };

 return (
   <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 py-12 px-4">
     <div className="max-w-md mx-auto">
       <button
         onClick={() => navigate('/')}
         className="flex items-center gap-2 text-gray-600 hover:text-gray-900 transition-colors mb-6"
       >
         <FiArrowLeft className="w-5 h-5" />
         <span>Volver al inicio</span>
       </button>

       <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
         <div className="bg-blue-600 px-6 py-4">
           <h1 className="text-xl font-semibold text-white">Confirmación de pago</h1>
         </div>

         <div className="p-6">
           {renderStatusContent()}

           {verificationStatus.state === 'success' && (
             <div className="mt-6 space-y-4">
               <h3 className="text-lg font-medium text-gray-800">Próximos pasos</h3>
               <ul className="space-y-2 text-gray-600">
                 <li className="flex items-center gap-2">
                   <FiCheckCircle className="text-green-500 flex-shrink-0" />
                   <span>Acceso inmediato a todas las funciones</span>
                 </li>
                 <li className="flex items-center gap-2">
                   <FiCheckCircle className="text-green-500 flex-shrink-0" />
                   <span>Recibirás un correo de confirmación</span>
                 </li>
               </ul>
             </div>
           )}

           <button
             onClick={() => navigate(verificationStatus.state === 'success' ? '/' : '/suscription', {
                 state: {
                   priceIdBasic: 'price_1Qm34MBodWVXIE1GzsMdQCNq',
                   cuponIdBasicToPro: 'TLzJ9VPO', 
                   cuponIdBasicToVip: 'H5ysYf2g',
                   cuponIdProToVip: 'ApOvaoPV'
                 }
               })}
             className="w-full mt-6 bg-blue-600 text-white py-3 rounded-xl hover:bg-blue-700 transition-all focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600"
           >
             {verificationStatus.state === 'success' ? 'Ir al Dashboard' : 'Reintentar Pago'}
           </button>
         </div>
       </div>
     </div>
   </div>
 );
};

export default OrderConfirmationPage;