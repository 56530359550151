import React, { useEffect, useState } from 'react';
import { useGlobal } from '../context/GlobalContext';
import CheckoutService from '../services/checkout';
import {
 BookOpen,
 MessageSquare,
 Trophy,
 Bell,
 Dumbbell,
 ClipboardList
} from 'lucide-react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { FiArrowLeft } from 'react-icons/fi';
import { useNavigate, useLocation } from 'react-router-dom';

const MySwal = withReactContent(Swal);

const FeatureIcon = ({ Icon, color }) => (
 <div className={`w-6 h-6 sm:w-8 sm:h-8 rounded-md ${color} flex items-center justify-center shadow-sm`}>
   <Icon className="w-3 h-3 sm:w-4 sm:h-4 text-white" />
 </div>
);

const SinglePlanPricingTable = ({
 priceIdBasic,
 cuponIdBasicToPro,
 cuponIdBasicToVip,
 cuponIdProToVip,
 abandonoCupon
}) => {
 const { state } = useGlobal();
 const { user } = state;
 const navigate = useNavigate();
 const [selectedCategory, setSelectedCategory] = useState('basic');
 
 const MONTHLY_PRICE = 49.99;

 useEffect(() => {
   if (cuponIdBasicToPro || cuponIdBasicToVip || cuponIdProToVip) {
     MySwal.fire({
       title: 'Información',
       text: 'Puedes mejorar tu plan en cualquier momento. Solo pagarás la diferencia al actualizar a un plan superior.',
       icon: 'info',
       confirmButtonText: 'Entendido'
     });
   }
 }, [cuponIdBasicToPro, cuponIdBasicToVip, cuponIdProToVip]);

 const handleSubscription = async (priceId, subscriptionCategory) => {
   try {
     if (!user) {
       alert("Usuario no disponible");
       return;
     }

     const planHierarchy = { basic: 1, pro: 2, vip: 3 };
     const currentPlanLevel = planHierarchy[user.subscriptionCategory] || 0;
     const newPlanLevel = planHierarchy[subscriptionCategory] || 0;

     if (newPlanLevel <= currentPlanLevel) {
       MySwal.fire({
         title: 'Cambio no permitido',
         text: 'No es posible cambiar a un plan inferior mientras tengas un compromiso de permanencia activo. Debes esperar a que finalice tu periodo actual.',
         icon: 'warning',
         confirmButtonText: 'Entendido'
       });
       return;
     }

     let couponId = null;

     // Primero verificamos si es plan básico y tiene cupón de abandono
     if (subscriptionCategory === 'basic' && abandonoCupon) {
       couponId = abandonoCupon;
     } else {
       // Si no es el caso, usamos la lógica normal de cupones
       switch (user.subscriptionCategory) {
         case 'basic':
           if (subscriptionCategory === 'pro') {
             couponId = cuponIdBasicToPro;
           } else if (subscriptionCategory === 'vip') {
             couponId = cuponIdBasicToVip;
           }
           break;
         case 'pro':
           if (subscriptionCategory === 'vip') {
             couponId = cuponIdProToVip;
           }
           break;
         default:
           break;
       }
     }

     const session = await CheckoutService.createCheckoutSessionSubscription(
       user.id,
       priceId,
       couponId,
       subscriptionCategory
     );
     window.location.href = session.session_url;
   } catch (error) {
     console.error("Error al suscribirse:", error);
     alert(error.message);
   }
 };

 const features = [
   { icon: BookOpen, label: 'Material de Estudio', value: 'Ilimitado', color: 'bg-blue-500' },
   { icon: ClipboardList, label: 'Tests', value: 'Ilimitado', color: 'bg-violet-500' },
   { icon: Dumbbell, label: 'Prácticos', value: 'Ilimitado', color: 'bg-emerald-500' },
   { icon: MessageSquare, label: 'Consultas', value: 'Ilimitado', color: 'bg-orange-500' },
   { icon: Bell, label: 'Notificaciones', value: '✓', color: 'bg-red-500' },
   { icon: Trophy, label: 'Retos', value: '✓', color: 'bg-amber-500' }
 ];

 const getPlanTitle = (category) => {
   switch (category) {
     case 'basic': return 'Plan Mensual';
     case 'pro': return 'Plan Semestral';
     case 'vip': return 'Plan Anual';
     default: return 'Plan Desconocido';
   }
 };

 const calculatePrices = (category) => {
   switch (category) {
     case 'basic': {
       if (abandonoCupon) {
         const discountPercentage = 20;
         const discountedMonthlyPrice = (MONTHLY_PRICE * (1 - discountPercentage/100)).toFixed(2);
         return {
           price: discountedMonthlyPrice,
           original: MONTHLY_PRICE.toFixed(2),
           period: 'mensual',
           priceId: priceIdBasic,
           billingInfo: 'Sin compromiso de permanencia',
           discount: discountPercentage
         };
       }
       return {
         price: MONTHLY_PRICE.toFixed(2),
         original: null,
         period: 'mensual',
         priceId: priceIdBasic,
         billingInfo: 'Sin compromiso de permanencia',
         discount: null
       };
     }
     
     case 'pro': {
       const discountPercentage = 30;
       const discountedMonthlyPrice = (MONTHLY_PRICE * (1 - discountPercentage/100)).toFixed(2);
       return {
         price: discountedMonthlyPrice,
         original: MONTHLY_PRICE.toFixed(2),
         period: 'mensual',
         priceId: priceIdBasic,
         billingInfo: 'Compromiso de permanencia 6 meses',
         discount: discountPercentage
       };
     }
     
     case 'vip': {
       const discountPercentage = 40;
       const discountedMonthlyPrice = (MONTHLY_PRICE * (1 - discountPercentage/100)).toFixed(2);
       return {
         price: discountedMonthlyPrice,
         original: MONTHLY_PRICE.toFixed(2),
         period: 'mensual',
         priceId: priceIdBasic,
         billingInfo: 'Compromiso de permanencia 12 meses',
         discount: discountPercentage
       };
     }
     
     default:
       return {
         price: 'N/A',
         original: null,
         period: '',
         priceId: null,
         billingInfo: '',
         discount: null
       };
   }
 };

 const planTitle = getPlanTitle(selectedCategory);
 const { price, original, period, priceId, billingInfo, discount } = calculatePrices(selectedCategory);
 const isCurrentPlan = user?.subscriptionCategory === selectedCategory;

 return (
   <div className="bg-gray-50 w-full overflow-hidden flex flex-col h-auto sm:h-screen items-center justify-center relative">
     <div className="absolute top-4 left-4">
       <button
         onClick={() => navigate(-1)}
         className="flex items-center gap-1 text-gray-600 text-sm sm:gap-2 sm:text-base"
       >
         <FiArrowLeft className="w-4 h-4 sm:w-5 sm:h-5" />
         <span>Volver</span>
       </button>
     </div>

     <div className="mt-16 sm:mt-0 w-[95%] max-w-md sm:max-w-lg md:max-w-xl lg:max-w-2xl bg-white rounded-2xl shadow-lg border border-gray-200 p-4 sm:p-6 flex flex-col">
       <div className="text-center mb-4 sm:mb-6">
         <h1 className="text-xl sm:text-2xl font-bold text-gray-900">Suscripción Única</h1>
         <p className="mt-1 text-sm sm:text-base text-gray-600">Elige la modalidad de tu suscripción</p>
       </div>

       <div className="flex justify-center items-center gap-2 sm:gap-4 mb-4 sm:mb-6">
         {['basic', 'pro', 'vip'].map((cat) => (
           <button
             key={cat}
             onClick={() => setSelectedCategory(cat)}
             className={`text-xs sm:text-sm px-3 py-1 sm:px-4 sm:py-2 rounded-full font-medium transition border border-gray-200 ${
               cat === selectedCategory
                 ? 'bg-indigo-600 text-white'
                 : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
             }`}
           >
             {cat === 'basic' && 'Mensual'}
             {cat === 'pro' && 'Semestral'}
             {cat === 'vip' && 'Anual'}
           </button>
         ))}
       </div>

       <div className="relative bg-white rounded-xl p-4 sm:p-5 shadow-md border border-gray-100 flex-1 flex flex-col justify-between">
         {isCurrentPlan && (
           <div className="absolute -top-3 left-0 w-full flex justify-center">
             <span className="bg-green-100 text-green-700 px-3 py-1 rounded-full text-xs sm:text-sm font-medium shadow">
               Plan Actual
             </span>
           </div>
         )}

         <div>
           <h3 className="text-base sm:text-lg font-bold text-gray-900 mb-3">{planTitle}</h3>

           <div className="mb-4 flex flex-col items-center space-y-2">
             <div className="text-center">
               {discount && (
                 <span className="bg-green-100 text-green-700 px-2 py-1 rounded-full text-xs font-medium mb-2 inline-block">
                   {discount}% descuento
                 </span>
               )}
               <div className="mt-2">
                 {original && (
                   <span className="text-gray-500 line-through text-sm mr-2">
                     {original}€/mes
                   </span>
                 )}
                 <span className="text-2xl sm:text-3xl font-extrabold text-gray-900">
                   {price}€/mes
                 </span>
               </div>
             </div>
             
             <div className="text-gray-500 text-sm text-center">
               <p>{billingInfo}</p>
             </div>
           </div>

           <div className="space-y-2 sm:space-y-3 mb-4 sm:mb-6">
             {features.map((feat, idx) => (
               <div key={idx} className="flex items-center gap-2 sm:gap-3">
                 <FeatureIcon Icon={feat.icon} color={feat.color} />
                 <div>
                   <p className="text-xs sm:text-sm text-gray-600">{feat.label}</p>
                   <p className="text-sm sm:text-base font-semibold text-gray-900">
                     {feat.value}
                   </p>
                 </div>
               </div>
             ))}
           </div>
         </div>

         <button
           onClick={() => handleSubscription(priceId, selectedCategory)}
           disabled={isCurrentPlan}
           className={`w-full rounded-md px-4 py-2 sm:py-3 font-bold text-center transition ${
             isCurrentPlan
               ? 'bg-green-100 text-green-700 cursor-not-allowed'
               : 'bg-indigo-600 text-white hover:bg-indigo-700'
           }`}
         >
           {isCurrentPlan ? 'Plan Actual' : 'Suscribirme'}
         </button>
       </div>

       <div className="mt-2 text-center text-xs sm:text-sm text-gray-500">
         Puedes mejorar tu plan en cualquier momento. Para cambiar a un plan inferior, deberás esperar a que finalice tu periodo actual.
       </div>
     </div>
   </div>
 );
};

const SinglePlanCheckoutPage = () => {
 const location = useLocation();
 const {
   priceIdBasic,
   cuponIdBasicToPro,
   cuponIdBasicToVip,
   cuponIdProToVip,
   abandonoCupon
 } = location.state || {};

 return (
   <SinglePlanPricingTable
     priceIdBasic={priceIdBasic}
     cuponIdBasicToPro={cuponIdBasicToPro}
     cuponIdBasicToVip={cuponIdBasicToVip}
     cuponIdProToVip={cuponIdProToVip}
     abandonoCupon={abandonoCupon}
   />
 );
};

export default SinglePlanCheckoutPage;