import api from '../utils/configAxios';

class CustomError extends Error {
 constructor(code, message) {
   super(message);
   this.code = code;
 }
}

const CheckoutService = {
 createCheckoutSession: async (userId, cantidad) => {
   try {
     const response = await api.post('/checkout/create-payment-session', {
       userId,
       cantidad,
     });
     return response.data;
   } catch (error) {
     throw error.response?.data?.error || 'Error al crear sesión de pago';
   }
 },

 createSimplePaymentSession: async (cantidad, userId) => {
   try {
     const response = await api.post(`/checkout/${userId}/create-payment-simple`, {
       cantidad,
     });
     return response.data;
   } catch (error) {
     throw new Error(error.response?.data?.error || 'Error al crear la sesión de pago simple');
   }
 },

 createCheckoutSessionSubscription: async (userId, price, couponId, subscriptionCategory) => {
   try {
     if (!['basic', 'pro', 'vip'].includes(subscriptionCategory)) {
       throw new Error('Categoría de suscripción no válida');
     }

     const data = { 
       userId, 
       price, 
       subscriptionCategory
     };
     
     if (couponId) {
       data.couponId = couponId;
     }
 
     const response = await api.post('/checkout/create-checkout-session-suscription', data);
     return response.data;
   } catch (error) {
     throw new Error(error.response?.data?.error || 'Error al crear sesión de pago para suscripción');
   }
 },

 createCheckoutSessionSubscriptionReanudar: async (userId, price) => {
   try {
     const response = await api.post('/checkout/create-checkout-reanudar-session-subscription', { 
       userId,
       price 
     });
     return response.data;
   } catch (error) {
     throw new Error(error.response?.data?.error || 'Error al reanudar la suscripción');
   }
 },

 applyCoupon: async (userId, couponId) => {
   try {
     const response = await api.post('/checkout/apply-coupon', {
       userId,
       couponId
     });
     return response.data;
   } catch (error) {
     throw new Error(error.response?.data?.error || 'Error al aplicar el cupón');
   }
 },

 cancelSubscription: async (userId) => {
   try {
     const response = await api.post('/checkout/cancel-subscription', { userId });
     return response.data;
   } catch (error) {
     const errorDetails = {
       userId,
       timestamp: new Date().toISOString(),
       error: error.message,
       statusCode: error.response?.status
     };
     
     console.error('Error en cancelación:', errorDetails);
 
     switch (error.response?.status) {
       case 404:
         throw new CustomError('USER_NOT_FOUND', 'Usuario no encontrado.');
       case 400:
         if (error.response.data.remainingMonths) {
           throw new CustomError(
             'PERMANENCE_PERIOD',
             `No puedes cancelar aún. Debes esperar ${error.response.data.remainingMonths} meses más.`
           );
         }
         throw new CustomError('INVALID_SUBSCRIPTION', error.response.data.message);
       case 500:
         throw new CustomError(
           'SERVER_ERROR',
           'Error en el servidor. Por favor, intenta más tarde.'
         );
       default:
         throw new CustomError('UNKNOWN_ERROR', 'Error desconocido');
     }
   }
 },

 continueSubscription: async (userId) => {
   try {
     const response = await api.post('/checkout/continue-subscription', { userId });
     return response.data;
   } catch (error) {
     throw new Error(error.response?.data?.error || 'Error al continuar la suscripción');
   }
 },

 verifyCheckoutSession: async (sessionId) => {
   try {
     const response = await api.post('/checkout/verify-checkout-session', { sessionId });
     console.log("verifyCheckout response:", response.data);
     return response.data;
   } catch (error) {
     console.error("verifyCheckout error:", error);
     throw new Error(error.response?.data?.error || 'Error al verificar la sesión');
   }
 },
};

export default CheckoutService;