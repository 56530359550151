import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SessionService from '../services/Session';

const CancelPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [sessionInfo, setSessionInfo] = useState(null);

  useEffect(() => {
    const checkSession = async () => {
      try {
        const params = new URLSearchParams(location.search);
        const sessionId = params.get('session_id');

        if (sessionId) {
          try {
            const sessionData = await SessionService.getSessionById(sessionId);
            setSessionInfo(sessionData);
          } catch (sessionError) {
            console.error('Error fetching session:', sessionError);
          }
        }
      } catch (error) {
        console.error('Error in checkSession:', error);
      } finally {
        setIsLoading(false);
      }
    };

    checkSession();
  }, [location]);

  const handleViewPlans = () => {
    navigate('/suscription', {
      state: {
        priceIdBasic: 'price_1Qm34MBodWVXIE1GzsMdQCNq',
        cuponIdBasicToPro: 'kx7cbKiv',
        cuponIdBasicToVip: 'mZrfDZPZ',
        cuponIdProToVip: 'tBksgnl9',
        abandonoCupon: 'mtxvAuaY'  // Añadimos esta nueva propiedad
      }
    })
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="animate-pulse text-center">
          <div className="w-12 h-12 border-4 border-blue-600 border-t-transparent rounded-full animate-spin mx-auto"></div>
          <p className="mt-4 text-gray-600">Verificando información...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 py-12">
        <div className="bg-white rounded-3xl shadow-lg overflow-hidden">
          <div className="bg-gradient-to-r from-[#4263EB] to-[#6366F1] p-8">
            <h1 className="text-3xl font-bold text-white mb-2">
              ¡Espera un momento!
            </h1>
            <p className="text-white/90 text-lg">
              Tenemos una oferta especial diseñada para ti
            </p>
          </div>

          <div className="p-8">
            <div className="grid md:grid-cols-2 gap-8">
              <div className="space-y-6">
                <div className="flex items-center space-x-4 p-4 bg-blue-50 rounded-2xl">
                  <div className="w-12 h-12 bg-blue-600 rounded-xl flex items-center justify-center">
                    <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <div>
                    <h3 className="font-semibold text-gray-900">Acceso Premium</h3>
                    <p className="text-sm text-gray-600">Todos los recursos y materiales desbloqueados</p>
                  </div>
                </div>

                <div className="flex items-center space-x-4 p-4 bg-purple-50 rounded-2xl">
                  <div className="w-12 h-12 bg-purple-600 rounded-xl flex items-center justify-center">
                    <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <div>
                    <h3 className="font-semibold text-gray-900">Descuento Especial</h3>
                    <p className="text-sm text-gray-600">20% adicional en todos nuestros planes</p>
                  </div>
                </div>

                <div className="flex items-center space-x-4 p-4 bg-green-50 rounded-2xl">
                  <div className="w-12 h-12 bg-green-600 rounded-xl flex items-center justify-center">
                    <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <div>
                    <h3 className="font-semibold text-gray-900">Soporte Premium</h3>
                    <p className="text-sm text-gray-600">Atención personalizada 24/7</p>
                  </div>
                </div>
              </div>

              <div className="bg-gray-50 rounded-2xl p-6 space-y-6">
                <div className="text-center">
                  <span className="text-sm font-semibold text-blue-600 bg-blue-100 px-3 py-1 rounded-full">
                    OFERTA LIMITADA
                  </span>
                  <div className="mt-4">
                    <span className="text-4xl font-bold text-gray-900">20%</span>
                    <span className="text-xl text-gray-600"> de descuento</span>
                  </div>
                  <p className="text-gray-500 mt-2">Oferta válida solo por hoy</p>
                </div>

                <button
                  onClick={handleViewPlans}
                  className="w-full bg-blue-600 text-white py-4 rounded-xl hover:bg-blue-700 transition-all font-semibold text-lg"
                >
                  ¡Sí, quiero mi descuento!
                </button>

                <button
                  onClick={() => navigate('/')}
                  className="w-full bg-white border border-gray-200 text-gray-600 py-4 rounded-xl hover:bg-gray-50 transition-all font-semibold"
                >
                  No, gracias
                </button>

                <div className="text-center text-sm text-gray-500">
                  <p>Garantía de satisfacción 100% durante 7 días</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelPage;