import axios from 'axios';

const baseUrl = 'https://opocheck.onrender.com/api/users';
// const baseUrl = 'http://localhost:3001/api/users';


// Crear instancia de axios con configuración base
const api = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Interceptor para añadir el token a todas las peticiones
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});


const UserService = {
  // 1) LOGIN
  login: async (phoneNumber, password) => {
    try {
      const response = await api.post('/login', { phoneNumber, password });
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
      }
      return response.data;
    } catch (error) {
      throw error?.response?.data?.error || 'Error al iniciar sesión';
    }
  },

  // 2) OBTENER INFO DEL USUARIO LOGUEADO
  getMe: async () => {
    try {
      const response = await api.get('/me');
      return response.data;
    } catch (error) {
      throw error?.response?.data?.error || 'Error al obtener datos de la sesión';
    }
  },

  // 3) LOGOUT
  logout: async () => {
    localStorage.removeItem('token');
    return { success: true };
  },

  // 4) REGISTRO
  register: async (phoneNumber, password, repeatPassword, email, userName) => {
    try {
      const response = await api.post('/register', {
        phoneNumber,
        password,
        repeatPassword,
        email,
        userName
      });
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
      }
      return response.data;
    } catch (error) {
      throw error.response?.data?.error || 'Error al registrar usuario';
    }
  },
 
  updateBannerActive: async (userId, bannerActive) => {
    try {
      const response = await api.patch(`/banner-active/${userId}`, { bannerActive });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.error || 'Error al actualizar bannerActive del usuario');
    }
  },
  
  // 6) AGREGAR NIVEL AL USUARIO
  addLevelToUser: async (userId, levelId) => {
    try {
      const response = await api.post(`/${userId}/addLevel`, { levelId });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.error || 'Error al agregar el nivel al usuario');
    }
  },
  
  // 7) AGREGAR NOTE PAD AL USUARIO
  addNotePadToUser: async (userId, notePadId) => {
    try {
      const response = await api.post(`/${userId}/notePads`, { notePadId });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.error || 'Error al agregar NotePad a usuario');
    }
  },
  
  // 8) ACTUALIZAR CATEGORÍA DE SUSCRIPCIÓN
  updateSubscriptionCategory: async (userId, subscriptionCategory) => {
    try {
      const response = await api.patch(`/${userId}/subscriptionCategory`, { subscriptionCategory });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.error || 'Error al actualizar la categoría de suscripción del usuario');
    }
  },

  // 9) AGREGAR O ACTUALIZAR TEST COMPLETADO
addOrUpdateTestCompleted: async (userId, testId, respuestas, preguntasData, score, categoryId) => {
  try {
    const response = await api.put(`/${userId}/addOrUpdateTestCompleted`, {
      testId,
      respuestas,
      preguntasData,
      score,
      categoryId
    });
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Error al actualizar test completado';
  }
},

// 10) REENVIAR CÓDIGO SMS
resendSMSCode: async (phoneNumber, verificationCode) => {
  try {
    const response = await api.post(`/resend-smsCode`, { phoneNumber, verificationCode });
    if (!response.data.success) {
      throw new Error(response.data.error || "Error en el servidor");
    }
    return response.data; 
  } catch (error) {
    const errorMessage = error.response?.data?.error || error.message || "Error de conexión";
    throw new Error(errorMessage);
  }
},

// 11) INCREMENTAR ÍNDICE DEL RETO
incrementIndexReto: async (userId) => {
  try {
    const response = await api.patch(`/${userId}/incrementIndexReto`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || 'Error al incrementar el índice del reto');
  }
},

// 12) ACTUALIZAR NIVEL DEL USUARIO
updateUserLevel: async (userId, levelName) => {
  try {
    const response = await api.patch(`/${userId}/level`, { levelName });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || 'Error al actualizar el nivel del usuario');
  }
},

// 13) CONFIRMAR REGISTRO
confirmRegistration: async (verificationCode) => {
  try {
    const response = await api.post(`/confirm-registration/${verificationCode}`);
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Error al confirmar registro';
  }
},

// 14) AÑADIR CRÉDITOS DE CORRECCIÓN
addCreditosCorreccion: async (userId, creditos) => {
  try {
    const response = await api.patch(`/${userId}/addCreditosCorreccion`, { creditos });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || 'Error al añadir créditos de corrección');
  }
},

// 15) COMPLETAR RETO
completeReto: async (userId, retoId) => {
  try {
    const response = await api.put(`/${userId}/completeReto/${retoId}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Error al completar el reto');
  }
},

// 16) CANJEAR RETO
canjeReto: async (userId, retoId) => {
  try {
    const response = await api.put(`/${userId}/canjeReto`, { retoId });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Error al canjear el reto');
  }
},

// 17) OBTENER RETOS COMPLETADOS
getRetosCompletados: async (userId) => {
  try {
    const response = await api.get(`/${userId}/retosCompletados`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Error al obtener los retos completados');
  }
},

// 18) AGREGAR O ACTUALIZAR PRÁCTICO COMPLETADO
addOrUpdatePracticoCompleted: async (userId, practicoId, enunciado, desarrollo, multiple, respuestas, correcciones) => {
  try {
    const response = await api.put(`/${userId}/addOrUpdatePracticoCompleted`, {
      practicoId,
      enunciado,
      desarrollo,
      multiple,
      respuestas,
      correcciones
    });
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Error al actualizar practico completado';
  }
},

// 19) OBTENER TESTS COMPLETADOS POR CATEGORÍA
getUserCompletedTestsByCategory: async (userId, categoryId) => {
  try {
    const response = await api.get(`/${userId}/${categoryId}`);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Error al obtener los tests completados';
  }
},

// 20) OBTENER USUARIO ACTUAL
getCurrentUser: async () => {
  try {
    const response = await api.get('/');
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Error al obtener datos del usuario';
  }
},

// 21) CONFIRMAR REFERIDO
confirmReferral: async (userId, referralCode) => {
  try {
    const response = await api.post(`/confirm-referral/${referralCode}`, { userId });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || 'Error al confirmar el referido');
  }
},

// 22) VERIFICAR Y CANJEAR REFERIDO
checkReferral: async (referralId, userId) => {
  try {
    const response = await api.post(`/check-referral`, { referralId, userId });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || 'Error al verificar y redimir el referido');
  }
},

// 23) OBTENER USUARIO POR ID
getUserById: async (userId) => {
  try {
    const response = await api.get(`/${userId}`);
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Error al obtener datos del usuario';
  }
},

// 24) OBTENER TODOS LOS USUARIOS
getAllUsers: async () => {
  try {
    const response = await api.get('/');
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Error al obtener datos del usuario';
  }
},

// 25) ACTUALIZAR EMAIL
updateEmail: async (userId, email) => {
  try {
    const response = await api.put(`/${userId}/email`, { email });
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Error al actualizar el email del usuario';
  }
},

// 26) OBTENER USUARIOS POR NIVEL
getUsersByLevel: async (levelId) => {
  try {
    const response = await api.get(`/level/${levelId}`);
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Error al obtener usuarios por nivel';
  }
},

// 27) ACTUALIZAR CAMPO "CUERPO"
updateCuerpo: async (userId, cuerpoId) => {
  try {
    const response = await api.put(`/${userId}/cuerpo`, { cuerpo: cuerpoId });
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Error al actualizar el cuerpo del usuario';
  }
},

// 28) ACTUALIZAR USUARIO
updateUser: async (userId, newData) => {
  try {
    const response = await api.put(`/${userId}`, newData);
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Error al actualizar datos del usuario';
  }
},

// 29) ACTUALIZAR NOMBRE DE USUARIO
updateUserName: async (userId, userName) => {
  try {
    const response = await api.put(`/${userId}/username`, { userName });
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Error al actualizar el nombre de usuario';
  }
},

// 30) AGREGAR ID DE CUERPO
addCuerpoId: async (id, cuerpoId) => {
  try {
    const response = await api.put(`/${id}`, { cuerpo: cuerpoId });
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Error al actualizar datos del usuario';
  }
},

// 31) SOLICITAR RESETEO DE CONTRASEÑA
resetPassword: async (email) => {
  try {
    const response = await api.post(`/recovery-password`, { email });
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Error al solicitar restablecimiento de contraseña';
  }
},

// 32) RESETEAR CONTRASEÑA CON CÓDIGO
resetPasswordWithCode: async (code, newPassword) => {
  try {
    const response = await api.post(`/reset-password`, { code, newPassword });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || 'Error al restablecer contraseña');
  }
},

// 33) ELIMINAR USUARIO
deleteUser: async () => {
  try {
    const response = await api.delete('/');
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Error al eliminar usuario';
  }
},

// 34) GUARDAR MATERIAL RESALTADO
saveHighlightedMaterial: async (userId, materialId, content) => {
  try {
    const response = await api.post(`/${userId}/highlightedMaterials`, { materialId, content });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || 'Error al guardar el material resaltado');
  }
},

// 35) OBTENER MATERIAL RESALTADO
fetchHighlightedMaterial: async (userId, materialId) => {
  try {
    const response = await api.get(`/${userId}/highlightedMaterials/${materialId}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || 'Error al obtener el material resaltado');
  }
}
  
};

export default UserService;
