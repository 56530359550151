

import axios from 'axios';

const BASE_URL = 'https://opocheckchat.onrender.com/api/consultas';
// const BASE_URL = 'http://localhost:3002/api/consultas'; // Cambia la URL base según la configuración de tu servidor
// axios.defaults.withCredentials = true;



const ConsultaService = {
 createConsulta: async (userId) => {
   try {
     const res = await axios.post(`${BASE_URL}/`, { userId });
     return res.data;
   } catch (error) {
     if (error.response?.status === 409) return error.response.data;
     throw error;
   }
 },

 getConsultasByUserId: async (userId) => {
   try {
     const res = await axios.get(`${BASE_URL}/user/${userId}`);
     return res.data;
   } catch (error) {
     throw error;
   }
 },

 updateConsultaByUserId: async (userId) => {
   try {
     const res = await axios.put(`${BASE_URL}/user/${userId}`);
     return res.data;
   } catch (error) {
     throw error;
   }
 }
};

export default ConsultaService;