import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { GlobalProvider, useGlobal } from './context/GlobalContext';

// Páginas que no requieren lazy loading por ser críticas
import OrderConfirmationPage from './components/OrderConfirmationPage';
import CancelPage from './components/CancelPage';
import SinglePlanCheckoutPage from './components/SinglePlanCheckoutPage';

// Importaciones lazy
const LandingPage = lazy(() => import('./components/LandingPage'));
const AppRoutes = lazy(() => import('./components/AppRoutes'));
const Verify = lazy(() => import('./components/Verify'));
const Register = lazy(() => import('./components/Register'));
const Login = lazy(() => import('./components/Login'));
const Reset = lazy(() => import('./components/RessePassword'));
const Terms = lazy(() => import('./components/Terminos&Condiciones'));
const Privacy = lazy(() => import('./components/Privacity'));
const CuerpoSelector = lazy(() => import('./components/Cuerpos').then(module => ({ default: module.default })));
// Configuración de rutas
const routeConfig = {
  public: [
    { path: '/landing', component: LandingPage },
    { path: '/login', component: Login },
    { path: '/register', component: Register },
    { path: '/reset', component: Reset },
    { path: '/terms-conditions', component: Terms },
    { path: '/privacy', component: Privacy },
    { path: '/suscription', component: SinglePlanCheckoutPage },
    { path: '/confirm', component: OrderConfirmationPage },
    { path: '/canceled', component: CancelPage }
  ],
  auth: [
    { path: '/verify', component: Verify, requiredState: 'unconfirmed' },
    { path: '/cuerpo', component: CuerpoSelector, requiredState: 'noCuerpo' },
    { path: '/*', component: AppRoutes, requiredState: 'full' }
  ]
};

// Componente de recuperación de estado
const StateRecovery = ({ children }) => {
  const { state, dispatch } = useGlobal();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!state.user && token && localStorage.getItem('lastKnownState')) {
      try {
        const savedState = JSON.parse(localStorage.getItem('lastKnownState'));
        if (savedState && savedState.user) {
          dispatch({ type: 'RECOVER_STATE', payload: savedState });
          
          const savedRoute = localStorage.getItem('lastRoute');
          if (savedRoute && !routeConfig.public.some(route => route.path === location.pathname)) {
            window.location.href = savedRoute;
          }
        }
      } catch (error) {
        console.error('Error recovering state:', error);
        localStorage.removeItem('lastKnownState');
        localStorage.removeItem('lastRoute');
        localStorage.removeItem('token');
        localStorage.removeItem('cuerpo');
      }
    }
  }, []);

  useEffect(() => {
    if (state.user) {
      localStorage.setItem('lastKnownState', JSON.stringify(state));
      localStorage.setItem('lastRoute', location.pathname);
    }
  }, [state, location.pathname]);

  return children;
};

// Componente de carga personalizado
const LoadingSpinner = () => (
  <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-90">
    <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
  </div>
);

// Componente de manejo de errores
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error in component:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="fixed inset-0 flex items-center justify-center bg-white">
          <div className="text-center">
            <h1 className="text-xl font-bold mb-4">Algo salió mal</h1>
            <button
              onClick={() => {
                this.setState({ hasError: false });
                window.location.href = '/';
              }}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Reintentar
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

// Componente de protección de rutas
const AuthGuard = ({ children, requiredState }) => {
  const { state } = useGlobal();
  const location = useLocation();
  const token = localStorage.getItem('token');

  const isPublicRoute = routeConfig.public.some(route => 
    route.path === location.pathname || location.pathname === '/landing'
  );

  if (isPublicRoute) {
    return children;
  }

  if (!token || !state.user) {
    return <Navigate to="/landing" replace />;
  }

  if (!state.user.confirmed && requiredState !== 'unconfirmed') {
    return <Navigate to="/verify" replace />;
  }

  if ((!state.cuerpo || !state.user.cuerpo) && requiredState === 'full') {
    return <Navigate to="/cuerpo" replace />;
  }

  return children;
};

// Componente principal de contenido
function AppContent() {
  const { state } = useGlobal();

  return (
    <Routes>
      {routeConfig.public.map(route => (
        <Route
          key={route.path}
          path={route.path}
          element={<route.component />}
        />
      ))}

      {routeConfig.auth.map(route => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <AuthGuard requiredState={route.requiredState}>
              <route.component />
            </AuthGuard>
          }
        />
      ))}

      <Route path="*" element={<Navigate to={state.user ? '/' : '/landing'} replace />} />
    </Routes>
  );
}

// Componente principal de la aplicación
function App() {
  return (
    <GlobalProvider>
      <ErrorBoundary>
        <Router>
          <StateRecovery>
            <Suspense fallback={<LoadingSpinner />}>
              <AppContent />
            </Suspense>
          </StateRecovery>
        </Router>
      </ErrorBoundary>
    </GlobalProvider>
  );
}

export default App;