import api from '../utils/configAxios';

const CuerpoService = {
 // 1) CREAR CUERPO
 createCuerpo: async (cuerpoData) => {
   try {
     const response = await api.post('/cuerpos', cuerpoData);
     return response.data;
   } catch (error) {
     throw error?.response?.data?.error || 'Error al crear cuerpo';
   }
 },

 // 2) OBTENER TODOS LOS CUERPOS  
 getAllCuerpos: async () => {
   try {
     const response = await api.get('/cuerpos');
     return response.data;
   } catch (error) {
     throw error?.response?.data?.error || 'Error al obtener cuerpos';
   }
 },

 // 3) OBTENER CUERPO POR ID
 getCuerpoById: async (id) => {
   try {
     const response = await api.get(`/cuerpos/${id}`);
     return response.data;
   } catch (error) {
     throw error?.response?.data?.error || 'Error al obtener cuerpo por ID';
   }
 },

 // 4) OBTENER CUERPO POR NOMBRE
 getCuerpoByName: async (name) => {
   try {
     const response = await api.get(`/cuerpos/byname/${name}`);
     return response.data;
   } catch (error) {
     throw error?.response?.data?.error || 'Error al obtener cuerpo por nombre';
   }
 },

 // 5) ACTUALIZAR CUERPO
 updateCuerpo: async (id, cuerpoData) => {
   try {
     const response = await api.put(`/cuerpos/${id}`, cuerpoData);
     return response.data;
   } catch (error) {
     throw error?.response?.data?.error || 'Error al actualizar cuerpo';
   }
 },

 // 6) ELIMINAR CUERPO
 deleteCuerpo: async (id) => {
   try {
     const response = await api.delete(`/cuerpos/${id}`);
     return response.data;
   } catch (error) {
     throw error?.response?.data?.error || 'Error al eliminar cuerpo';
   }
 }
};

export default CuerpoService;